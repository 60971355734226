<template>
  <app-loading-container
    :loading="loading"
    :loaded="loaded"
    :error-loading="errorLoading"
  >
    <h1 class="text-base font-bold text-gray-type-2">Add Notification Email</h1>
    <div class="w-full mt-6 lg:w-2/4">
      <form @submit.prevent="addNewEmail">
        <label class="text-xs font-medium text-gray-type-3" for="email"
          >Enter email address</label
        >
        <div class="relative flex items-center mt-2">
          <input
            v-model="$v.form.email.$model"
            type="email"
            id="email"
            class="w-full h-12 px-3 py-4 pl-4 text-xs font-medium outline-none bg-gray-type-4 placeholder-gray-type-3 focus:outline-none"
          />
          <app-button
            type="submit"
            class="w-1/3 h-12 py-3 ml-6 text-xs font-bold text-center text-white rounded px-9 bg-green-type-1 focus:outline-none focus:ring"
            :loading="addingNewEmail"
          >
            Add Email
          </app-button>
        </div>
        <span
          class="text-xs font-bold text-red-400"
          v-if="$v.form.email.$error"
          >Please enter email address</span
        >
      </form>
      <h1 class="mt-8 text-base font-bold text-gray-type-2">Existing emails</h1>
      <p class="mt-2 text-xs font-medium text-gray-type-8">
        We will send the trip reports to these emails
      </p>
      <div class="mt-6">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="flex items-center justify-between">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
            <div class="flex justify-between mt-6">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
            <div class="flex justify-between mt-6">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="flex items-center justify-between py-5"
            v-for="email in emails"
            :key="email.id"
          >
            <div class="text-xs font-medium text-gray-type-3">
              {{ email.email }}
            </div>
            <button
              v-if="!email.is_default"
              @click="showDeleteEmailModal(email)"
            >
              <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0h20v20H0z" />
                  <path
                    d="M14.167 5h4.166v1.667h-1.666V17.5c0 .46-.373.833-.834.833H4.167a.833.833 0 01-.834-.833V6.667H1.667V5h4.166V2.5c0-.46.373-.833.834-.833h6.666c.46 0 .834.373.834.833V5zM7.5 9.167v5h1.667v-5H7.5zm3.333 0v5H12.5v-5h-1.667zM7.5 3.333V5h5V3.333h-5z"
                    fill="#AC3535"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </button>
          </div>
        </template>
      </div>
    </div>
    <modal :show="showConfirmDeleteEmail">
      <div class="p-6" v-if="selectedEmail">
        <div class="flex items-center justify-between mt-4">
          <button
            aria-label="Close panel"
            class="transition duration-150 ease-in-out text-gray-type-2"
            @click="showConfirmDeleteEmail = false"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto font-bold text-gray-type-2">
            Delete notification email
          </div>
        </div>
        <div class="mt-6">
          <p class="text-xs font-medium text-center text-gray-type-8">
            Are you sure you want to delete
            <span>{{ selectedEmail.email }}</span
            >?
          </p>
          <div class="flex items-center justify-between mt-16 space-x-6">
            <button
              class="w-full h-12 py-4 font-bold text-center text-white rounded bg-gray-type-11"
              @click="showConfirmDeleteEmail = false"
            >
              Cancel
            </button>
            <app-button
              class="justify-center w-full h-12 py-4 font-bold text-center text-white rounded bg-red-type-1"
              @click="deleteEmail"
              :loading="processing"
            >
              Delete Email
            </app-button>
          </div>
        </div>
      </div>
    </modal>
  </app-loading-container>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import { required, email } from "@vuelidate/validators";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
      loaded: false,
      errorLoading: false,
      emails: [],
      form: {
        email: "",
      },
      processing: false,
      errorMessage: "",
      showConfirmDeleteEmail: false,
      selectedEmail: null,
      addingNewEmail: false,
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
      },
    };
  },
  created() {
    this.fetchEmails();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    fetchEmails() {
      this.loading = true;

      this.errorLoading = this.loaded = false;

      this.$http
        .get(`/corporates/${this.user.id}/notification-emails`)
        .then((res) => {
          this.emails = res.data;
          this.loaded = true;
        })
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDeleteEmailModal(email) {
      this.selectedEmail = email;
      this.showConfirmDeleteEmail = true;
    },
    addNewEmail() {
      this.$v.form.$touch();

      if (this.addingNewEmail || this.$v.form.$error) {
        return;
      }

      this.addingNewEmail = true;

      this.$http
        .post(`/corporates/${this.user.id}/notification-emails`, {
          ...this.form,
        })
        .then(() => {
          this.$v.form.$reset();

          this.form.email = "";

          this.fetchEmails();

          this.$toast.success("Email addedd successfully.");
        })
        .catch(() => {
          this.$toast.error(
            "We were unable to add this email, please try again."
          );
        })
        .finally(() => {
          this.addingNewEmail = false;
        });
    },
    deleteEmail() {
      this.processing = true;

      this.$http
        .delete(
          `/corporates/${this.user.id}/notification-emails/${this.selectedEmail.id}`
        )
        .then(() => {
          this.fetchEmails();

          this.showConfirmDeleteEmail = false;
          this.selectedEmail = null;

          this.$toast.success("Email deleted successfully.");
        })
        .catch(() => {
          this.$toast.error(
            "We were unable to remove this email, please try again."
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>